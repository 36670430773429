'use client'

import {
  ArrowBackIcon,
  Button,
  CloseCircleIcon,
  SearchIcon,
  useClickOutside,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale'
import { useNavigation } from '@hooks/useNavigation'
import { useSearchContext } from '@hooks/useSearchContext'
import { useStage } from '@hooks/useStage'
import useTranslateMessage from '@hooks/useTranslateMessage'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import { setRecentSearch } from '@lib/utils/localStorageHelpers'
import { getIndexFromLocale, useResetSearchSorting } from '@lib/utils/searchEngineHelpers'
import { usePathname } from 'next/navigation'
import React, { useEffect, useRef, useState } from 'react'
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch'
import RecentSearch from './Recent'
import { SearchAreaContainer, StyledSearchArea } from './Search.styles'

export const SearchField = (props: UseSearchBoxProps) => {
  const MIN_CHARS_VALID_SEARCH = 2
  const locale = useLocale()
  const searchIndex = getIndexFromLocale(locale)
  const theme = useTheme()
  const navigation = useNavigation()
  const stage = useStage()
  const region = getRegionFromLocale(locale)
  const { t } = useTranslateMessage()
  const { refine, query } = useSearchBox(props)
  const { searchValue: searchValueLocal, setSearchValue: setSearchValueLocal } = useSearchContext()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const pathname = usePathname()
  const { resetSorting } = useResetSearchSorting()

  const [recentSearchView, setRecentSearchView] = useState(false)
  const resultsContainerRef = useRef<HTMLDivElement>(null)
  const typingTimeout = useRef<NodeJS.Timeout | null>(null)
  const recentSearchTimeout = useRef<NodeJS.Timeout | null>(null)

  const hideSearch = () => setRecentSearchView(false)
  const onSearchPage = pathname?.includes('/search')

  useClickOutside(resultsContainerRef, hideSearch)

  const handleClearSearch = () => {
    setSearchValueLocal('')
    refine('')
    resetSorting()
  }

  const triggerSearch = () => {
    hideSearch()
    !onSearchPage && navigation.navigate.searchPage(searchIndex, searchValueLocal)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    hideSearch()
    if (event.code === 'Enter') {
      return triggerSearch()
    }
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const newValue = event.currentTarget.value
    setSearchValueLocal(newValue)

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current)
    }
    typingTimeout.current = setTimeout(() => {
      if (!onSearchPage) {
        if (newValue.length > MIN_CHARS_VALID_SEARCH) {
          return navigation.navigate.searchPage(searchIndex, newValue)
        }
      } else {
        refine(newValue)
      }
    }, 350)

    if (recentSearchTimeout.current) {
      clearTimeout(recentSearchTimeout.current)
    }

    recentSearchTimeout.current = setTimeout(() => {
      setRecentSearch(newValue, region, stage)
    }, 3000)
  }

  useEffect(() => {
    document.body.style.overflow = recentSearchView && !isDesktop ? 'hidden' : 'auto'
  }, [recentSearchView])

  useEffect(() => {
    const searchQueryFromSearchParams = searchValueLocal.length === 0 && query.length > 0
    if (onSearchPage && searchQueryFromSearchParams) {
      setSearchValueLocal(query)
    }
  }, [query])

  return (
    <SearchAreaContainer ref={resultsContainerRef}>
      <StyledSearchArea
        hiddenLabel
        autoComplete="off"
        autoFocus={onSearchPage}
        variant="standard"
        placeholder={t({ id: 'header.search.placeholder' })}
        value={searchValueLocal}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        InputProps={{
          onClick: () => !recentSearchView && setRecentSearchView(true),
          startAdornment: (
            <Button
              onClick={!isDesktop && recentSearchView ? hideSearch : triggerSearch}
              aria-label={!isDesktop && recentSearchView ? 'back' : 'search'}
              variant="ghost"
              className={!isDesktop && recentSearchView ? undefined : 'search-action-button'}
            >
              {!isDesktop && recentSearchView ? (
                <ArrowBackIcon width="24px" />
              ) : (
                <SearchIcon width="24px" height="26px" />
              )}
            </Button>
          ),
          endAdornment: Boolean(searchValueLocal?.length) && (
            <Button onClick={handleClearSearch} className="clear-search-value" variant="ghost">
              <CloseCircleIcon width="24px" />
            </Button>
          )
        }}
      />
      {recentSearchView && <RecentSearch updateTextField={setSearchValueLocal} hideSearch={hideSearch} />}
    </SearchAreaContainer>
  )
}
