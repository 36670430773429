import type { BookInterface, UserListInterface } from '@bookis/legacy-types'
import useSWR from 'swr'
import { useCallback, useMemo } from 'react'
import { createListItem, deleteListItem, getList } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useYourList = ({ listId }: { listId: number | null }) => {
  const cacheKey = buildCacheKey('useYourList', { listId })

  const { data: list, isLoading, mutate: dispatchListUpdate } = useSWR(cacheKey, () => getList({ listId: listId! }))

  const addItem = useCallback(
    async (bookId: number) => {
      if (!listId || !list) return

      const optimisticData = {
        ...list,
        books: [...list.books, { id: bookId } as BookInterface]
      } satisfies UserListInterface

      await dispatchListUpdate(
        async () => {
          await createListItem({
            listId,
            bookId
          })
          return optimisticData
        },
        {
          optimisticData,
          rollbackOnError: true
        }
      )
    },
    [dispatchListUpdate, list]
  )

  const removeItem = useCallback(
    async (bookId: number) => {
      if (!listId || !list) return

      const optimisticData = {
        ...list,
        books: list.books.filter((book: { id: number }) => book.id !== bookId)
      } satisfies UserListInterface

      await dispatchListUpdate(
        async () => {
          await deleteListItem({
            listId,
            bookId
          })
          return optimisticData
        },
        {
          optimisticData,
          rollbackOnError: true
        }
      )
    },
    [dispatchListUpdate, list]
  )

  const listItemIds = useMemo(() => list?.books.map((book: { id: number }) => book.id) ?? [], [list])

  return {
    data: list,
    isLoading,
    addItem,
    removeItem,
    hasItem: (bId: number) => listItemIds.includes(bId),
    refresh: dispatchListUpdate
  }
}
