import axios from 'axios'
import { getActiveTenant } from '../setActiveTenant'
import { getActiveUser } from '../setActiveUser'
import { Region } from '../utils/region'
import { getConfigVariable } from '../utils/config'

const HERMES_API_BASE_URL = getConfigVariable('HERMES_API_URL')

const getApiVersion = (version?: string) => {
  const DEFAULT_VERSION = 'v1'
  const versions = new Set(['v1'])

  if (!version) return DEFAULT_VERSION

  return versions.has(version) ? version : DEFAULT_VERSION
}

const getApiTenant = () => {
  const tenant = getActiveTenant()

  const DEFAULT_TENANT = Region.NORWAY
  const tenants = new Set(Object.values(Region))

  if (!tenant) return DEFAULT_TENANT

  return tenants.has(tenant) ? tenant : DEFAULT_TENANT
}

const getAuthToken = () => {
  const user = getActiveUser()
  if (!user) throw new Error('No user is active')
  return user.token
}

export const authenticatedApiInstance = (version?: string) =>
  axios.create({
    baseURL: `${HERMES_API_BASE_URL}/${getApiVersion(version)}/${getApiTenant()}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`
    }
  })

export const publicApiInstance = (version?: string) =>
  axios.create({
    baseURL: `${HERMES_API_BASE_URL}/${getApiVersion(version)}/${getApiTenant()}`,
    headers: {
      'Content-Type': 'application/json'
    }
  })
