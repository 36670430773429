import { useMemo, useState } from 'react'
import useSWR from 'swr'
import { searchBooks } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useBookSearch = ({
  isbn13,
  authorIds,
  excludeBookIds,
  isAvailable
}: {
  isbn13?: string[]
  authorIds?: number[]
  excludeBookIds?: number[]
  isAvailable?: boolean
}) => {
  const [searchParams, setSearchParams] = useState<{
    isbn13?: string[]
    authorIds?: number[]
    isAvailable?: boolean
    excludeBookIds?: number[]
  }>({})

  const cacheKey = buildCacheKey('useBookSearch', {}, { isbn13, authorIds, isAvailable, ...searchParams })

  const { data, isLoading } = useSWR(cacheKey, () => searchBooks({ isbn13, authorIds, isAvailable }), {
    revalidateIfStale: false,
    revalidateOnReconnect: false
  })

  const filteredData = useMemo(() => data?.filter((book) => !excludeBookIds?.includes(book.id)), [data, excludeBookIds])

  return {
    data: filteredData,
    isLoading,
    search: setSearchParams
  }
}
