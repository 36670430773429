import useSWRInfinite from 'swr/infinite'
import { useMemo } from 'react'
import { buildCacheKey } from '../../utils/cache'
import { getRecommendedListingsToUserByBookUuid } from './api'

type DeliveryMethod = 'shipping' | 'meetup' | 'both'

export const useListingsToSeller = ({
  bookUuid,
  sellerUuid,
  searchQuery,
  excludeListingIds,
  size = 10,
  deliveryMethod = 'shipping'
}: {
  bookUuid: string
  sellerUuid: string
  searchQuery?: string
  excludeListingIds?: number[]
  size?: number
  deliveryMethod?: string
}) => {
  const getCacheKey = (pageIndex: number, previousPageData: any) => {
    if (pageIndex === 0 && previousPageData && previousPageData.length) return null
    return {
      key: buildCacheKey(
        'useListingsToSeller',
        { sellerUuid, bookUuid },
        { size, deliveryMethod, searchQuery, excludeListingIds }
      ),
      pageIndex
    }
  }

  const deliveryMethods: DeliveryMethod[] = deliveryMethod === 'shipping' ? ['shipping', 'both'] : ['meetup']

  const {
    data: dataByPage,
    isLoading,
    setSize: setPage,
    size: currentPage
  } = useSWRInfinite(getCacheKey, ({ pageIndex }) =>
    getRecommendedListingsToUserByBookUuid(
      { sellerUuid, bookUuid },
      { page: pageIndex + 1, size, deliveryMethods, searchQuery, excludeListingIds }
    )
  )

  const data = useMemo(() => dataByPage?.map((pg) => pg.data).flat(), [dataByPage])
  const pagination = useMemo(() => dataByPage?.findLast((pg) => pg.pagination)?.pagination, [dataByPage])
  const hasMore = pagination?.continue

  return {
    data,
    pagination,
    isLoading:
      isLoading || (currentPage > 0 && dataByPage && typeof dataByPage[currentPage - 1] === 'undefined') || false,
    nextPage: () => hasMore && setPage(currentPage + 1)
  }
}
