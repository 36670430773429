const normalizeArgs = (args: Record<string, unknown>) =>
  Object.keys(args)
    .sort()
    .reduce<Record<string, unknown>>((acc, k) => {
      if (args[k] != null && args[k] !== '') {
        acc[k] = args[k]
      }
      return acc
    }, {})

export const buildCacheKey = (
  key: string,
  requiredArgs: Record<string, unknown> = {},
  optionalArgs: Record<string, unknown> = {}
) => {
  const hasInvalidRequiredArgs = Object.values(requiredArgs).some((value) => value === undefined || value === null)

  if (hasInvalidRequiredArgs) {
    return null
  }

  const normalizedRequired = normalizeArgs(requiredArgs)
  const normalizedOptional = normalizeArgs(optionalArgs)
  return { key, ...normalizedRequired, ...normalizedOptional }
}
