import useSWR from 'swr'
import { buildCacheKey } from '../../utils/cache'
import { validateAddressByShippingOption } from '../fulfillment/api'
import { useShippingOptions } from './use-shipping-options'

export const useShippingOptionsToAddress = ({
  addressId,
  partyType
}: {
  addressId: number | null
  partyType: 'sender' | 'recipient'
}) => {
  const { data: rawShippingOptions, isLoading: optionsLoading } = useShippingOptions()
  const cacheKey = addressId
    ? buildCacheKey('useShippingOptionsToAddress', {
        addressId,
        partyType,
        shippingOptions: Object.values(rawShippingOptions ?? {})
      })
    : null

  const { data: validationResults, isLoading: validationLoading } = useSWR(cacheKey, async () => {
    if (!rawShippingOptions || !addressId) return null

    const results = await Promise.all(
      rawShippingOptions.map((option) =>
        validateAddressByShippingOption(
          { fulfillmentProviderId: option.providerId },
          { optionId: option.id, addressId, partyType }
        )
      )
    )
    return rawShippingOptions.filter((option) => results.find((r) => r.optionId === option.id)?.isValid)
  })

  return {
    data: !validationLoading ? validationResults ?? rawShippingOptions : null,
    isLoading: optionsLoading || validationLoading || false
  }
}
