import axios, { AxiosError, AxiosInstance } from 'axios'
import { getActiveTenant } from '../setActiveTenant'
import { getActiveUser } from '../setActiveUser'
import { Region } from '../utils/region'
import { getConfigVariable } from '../utils/config'

const LEGACY_API_BASE_URL = getConfigVariable('LEGACY_API_URL')

type LegacyApiError = {
  message: string
  code: number
  data?: unknown
}

const isLegacyApiError = (error: unknown): error is LegacyApiError =>
  typeof error === 'object' &&
  error !== null &&
  'message' in error &&
  'code' in error &&
  typeof (error as LegacyApiError).message === 'string' &&
  typeof (error as LegacyApiError).code === 'number'

const createErrorInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const errorData = error.response?.data
      const apiError = {
        message: isLegacyApiError(errorData) ? errorData.message : error.message,
        code: error.response?.status || 500,
        data: errorData
      }
      return Promise.reject(apiError)
    }
  )
  return instance
}

const getApiTenant = () => {
  const tenant = getActiveTenant()

  const DEFAULT_TENANT = Region.NORWAY
  const tenants = new Set(Object.values(Region))

  if (!tenant) return DEFAULT_TENANT

  return tenants.has(tenant) ? tenant : DEFAULT_TENANT
}

const getAuthToken = () => {
  const user = getActiveUser()
  if (!user) throw new Error('No user is active')
  return user?.token
}

export const authenticatedApiInstance = () => {
  const instance = axios.create({
    baseURL: LEGACY_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
      countryCode: getApiTenant()
    }
  })

  return createErrorInterceptor(instance)
}

export const publicApiInstance = () => {
  const instance = axios.create({
    baseURL: LEGACY_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      countryCode: getApiTenant()
    }
  })

  return createErrorInterceptor(instance)
}
