import { useMemo } from 'react'
import { ListingDto } from '@hermes/domains'
import useSWRInfinite from 'swr/infinite'
import { buildCacheKey } from '../../utils/cache'
import { getRecommendedListingsToBookByBookUuid } from './api'

type DeliveryMethod = 'shipping' | 'meetup' | 'both'

export const useListingsToBook = ({
  bookUuid,
  size = 10,
  deliveryMethod = 'shipping'
}: {
  bookUuid: string
  size?: number
  deliveryMethod?: string
}) => {
  const getCacheKey = (pageIndex: number, previousPageData: any) => {
    if (pageIndex === 0 && previousPageData && previousPageData.length) return null
    return {
      key: buildCacheKey('useListingsToBook', { bookUuid }, { size, deliveryMethod }),
      pageIndex
    }
  }

  const deliveryMethods: DeliveryMethod[] = deliveryMethod === 'shipping' ? ['shipping', 'both'] : ['meetup']

  const {
    data: dataByPage,
    isLoading,
    setSize: setPage,
    size: currentPage
  } = useSWRInfinite(getCacheKey, ({ pageIndex }) =>
    getRecommendedListingsToBookByBookUuid({ bookUuid }, { page: pageIndex + 1, size, deliveryMethods })
  )

  const data: ListingDto[] = useMemo(() => dataByPage?.map((pg) => pg.data).flat() || [], [dataByPage])
  const pagination = useMemo(() => dataByPage?.findLast((pg) => pg.pagination)?.pagination, [dataByPage])

  const hasMore = pagination?.continue

  return {
    data,
    pagination,
    isLoading:
      isLoading || (currentPage > 0 && dataByPage && typeof dataByPage[currentPage - 1] === 'undefined') || false,
    nextPage: () => hasMore && setPage(currentPage + 1)
  }
}
