import useSWR from 'swr'
import { useCallback } from 'react'
import { addToWaitingList, getWaitingListStatusByBookId, removeFromWaitingList } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useWaitingList = (bookId: number) => {
  const cacheKey = buildCacheKey('use-waiting-list', { bookId })
  const {
    data,
    isLoading,
    mutate: dispatchWaitingListUpdate
  } = useSWR(cacheKey, () => getWaitingListStatusByBookId(bookId))

  const addItem = useCallback(async () => {
    const optimisticData = true
    await dispatchWaitingListUpdate(
      async () => {
        await addToWaitingList(bookId)
        return optimisticData
      },
      { rollbackOnError: true, optimisticData }
    )
  }, [bookId, dispatchWaitingListUpdate])

  const removeItem = useCallback(async () => {
    const optimisticData = false
    await dispatchWaitingListUpdate(
      async () => {
        await removeFromWaitingList(bookId)
        return optimisticData
      },
      { rollbackOnError: true, optimisticData }
    )
  }, [bookId, dispatchWaitingListUpdate])

  return { hasItem: data ?? false, isLoading, addItem, removeItem }
}
