import { ListingDto } from '@hermes/domains'
import { buildSearchParams } from '../../utils/url'
import { publicApiInstance } from '../client'
import { PaginatedApiResponse } from '../types.api'

export const getRecommendedListingsToBookByBookUuid = async (
  params: { bookUuid: string },
  query: { page: number; size: number; deliveryMethods?: ('shipping' | 'meetup' | 'both')[] }
): Promise<PaginatedApiResponse<ListingDto>> =>
  publicApiInstance()
    .get(`/listings/book/${params.bookUuid}/recommended`, {
      params: buildSearchParams(query)
    })
    .then((res) => res.data)

export const getRecommendedListingsToUserByBookUuid = async (
  params: { sellerUuid: string; bookUuid: string },
  query: {
    page: number
    size: number
    deliveryMethods?: ('shipping' | 'meetup' | 'both')[]
    searchQuery?: string
    excludeListingIds?: number[]
  }
): Promise<PaginatedApiResponse<ListingDto>> =>
  publicApiInstance()
    .get(`/listings/user/${params.sellerUuid}/book/${params.bookUuid}/recommended`, {
      params: buildSearchParams(query)
    })
    .then((res) => res.data)
